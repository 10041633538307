import {
  PostCard,
  PostsByCategory,
  useSitePosts,
} from '@entities/post';
import { t } from '@lib/i18n';
import Typography from '@ui/Typography';
import { Layout } from '@widgets/layout';
import { VideoSection } from './ui/VideoSection';
import { LeadMagnitBanner } from './ui/LeadMagnitBanner';

export const BlogV2 = () => {
  const groupedPosts = useSitePosts();

  return (
    <Layout seo={t('seo.blog')} banner bodyClassName="bg-tertiary100">
      <Layout.Intro>
        <div className="grid-layout mx-auto flex flex-col items-start pb-8 lg-down:items-center lg-down:pb-2">
          <Typography variant="header1" className="pt-8 lg-down:text-center">
            {t('blog.header')}
          </Typography>
          <Typography variant="subhead1" className="mt-4 lg-down:text-center">
            {t('blog.subheader')}
          </Typography>
          <PostCard
            post={groupedPosts.general !== undefined ? groupedPosts.general[0] : groupedPosts.all[0]}
            isGeneral
            className="lg:mt-8 lg-down:mt-7"
          />
        </div>
      </Layout.Intro>
      <div className="grid-layout mx-auto lg:py-8 lg-down:pt-5 lg-down:pb-7">
        <PostsByCategory posts={groupedPosts} />
      </div>
      <LeadMagnitBanner />
      <VideoSection />
    </Layout>
  );
};
